<template>
  <div class="filter-sidebar" id="filter-sidebar">
    <div class="flex filter-title">
      <h4>फिल्टरहरू</h4>
    </div>
    <div class="filter-sidebar-inner-scroll">
      <div class="filter-label-title">सेवा दिने कार्यालय</div>
      <div
        class="services-filter"
        v-for="(ele, index) in service_provider_arr"
        :key="`provider-${index}`"
      >
        <label class="radio-wrapper services-filter__label">
          <input
            type="radio"
            class="map-filter__input"
            :value="ele.key"
            v-model="service_provider"
            @click="changeServiceProvider"
          />
          <span class="radiomark"></span>
          {{ ele.name_ne }}
        </label>
      </div>
      <div class="filter-label-title">सेवाको प्रकार</div>
      <div
        class="services-filter"
        v-for="(ele, index) in service_type_arr"
        :key="`type-${index}`"
      >
        <label class="radio-wrapper services-filter__label">
          <input
            type="radio"
            :value="ele.id"
            v-model="service_type"
            class="map-filter__input"
            @click="changeServiceType"
          />
          <span class="radiomark"></span>
          {{ ele.label }}
        </label>
      </div>
      <!-- <button type="submit" class="btn btn--gradient">फिल्टर गर्नुहाेस्</button> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    service_type_arr: { type: Array, required: true },
    service_provider_arr: { type: Array, required: true },
  },
  data() {
    return {
      service_provider: "",
      service_type: "",
    };
  },
  methods: {
    changeServiceProvider(event) {
      let tmp = event.target.value;

      if (event.target.value === this.service_provider) {
        this.service_provider = "";
        tmp = "";
      }

      this.$emit("changeInRadioFilter", {
        type: "service_provider",
        value: tmp,
      });
    },
    changeServiceType(event) {
      let tmp = event.target.value;

      if (event.target.value == this.service_type) {
        this.service_type = "";
        tmp = "";
      }

      this.$emit("changeInRadioFilter", {
        type: "service_type",
        value: tmp,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
